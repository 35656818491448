import { Agent } from '@agentsonly/models';
import { Col, Form as AntdForm, Input, Row, Typography } from 'antd';
import React from 'react';

import { getPersonalIdentificationField } from '../../../utils/personalIdentificaiton';

const { Paragraph } = Typography;

type Props = {
  agent: Agent;
  disabled: boolean;
};

export const PersonalIdentificationField: React.FC<Props> = ({
  agent,
  disabled,
}) => {
  const field = getPersonalIdentificationField(agent.address.countryCode);

  if (!field) {
    return null;
  }

  return (
    <Row>
      <Col span={24}>
        <Paragraph strong>Personal Identification</Paragraph>
      </Col>
      <Col span={24}>
        <AntdForm.Item
          key={field.name}
          label={field.title}
          name={field.name}
          initialValue={agent.legal?.[field.name]}
          rules={[
            () => ({
              validator(_, value: string) {
                return field.validation(value);
              },
            }),
          ]}
        >
          <Input disabled={disabled} />
        </AntdForm.Item>
      </Col>
    </Row>
  );
};
