export function isDev(): boolean {
  return process.env.REACT_APP_ENV === 'dev';
}

export function isStage(): boolean {
  return process.env.REACT_APP_ENV === 'stage';
}

export function isProd(): boolean {
  return process.env.REACT_APP_ENV === 'prod';
}

export const agentSkillTypingTestId = () => {
  switch (true) {
    case isDev(): {
      return 'sxLi4OIZdaauvS8mGzyh';
    }
    case isStage(): {
      return 'sxLi4OIZdaauvS8mGzyh';
    }
    case isProd(): {
      return 'sxLi4OIZdaauvS8mGzyh';
    }
    default: {
      return 'sxLi4OIZdaauvS8mGzyh';
    }
  }
};

export const getCryptoPublicKey = (): JsonWebKey => {
  if (isProd()) {
    return {
      alg: 'RSA-OAEP-256',
      e: 'AQAB',
      ext: true,
      key_ops: ['encrypt'],
      kty: 'RSA',
      n: 'xfY_8SJ0eKzmGVGWjUo7RvcQC8KDX0HHw5UG4ZYRy--AI9a7SRZ6_LHzkOMKgzHBwRVCYlSAYUn3Ps7AuutJRgGouhdcdPZfBHyplf2eGBSQMdD2IHZx5fInoV7aY-MVvGv-aDigtlz3qJlX5F_Amj7ZhysOB4ZzVieLWYfSEkilM0AFrPJZ1EevPQLe7Vxb5tBtqGfFkn4CJrj8GI1B4723XMN4d_qsHHExmQgurINP3GOx-8achGRVZLqcyzT6wCJpNWwQnTy_9gOFIdu9lEaE7SCq_NqaY2PKfW1xV4iRuZaEU338-GpHZ5ZB295hgMfuX-7oG4oEvJaam3Nutw',
    };
  }

  if (isStage()) {
    return {
      alg: 'RSA-OAEP-256',
      e: 'AQAB',
      ext: true,
      key_ops: ['encrypt'],
      kty: 'RSA',
      n: 'xKHssuxw7TLnqyEnmutg9RvepRuVqU-mgwi3l2GzGde2wwg_DVLPxZn6I28-scC0c6OTFDnx8aWcWQeRWTLvlQ8v7q-yhzKpzGNJJCPTwyyNClc-hNlmFvlE1A0uLivuaT_CPbiOBOZmGsQiRnrZfBy9KYRyAxVm92SmxJcdI0VJFcIWNtYZcGy91Kc-VtWtR9TvcUOquc5Ja8835eIH7FZHd4NhNphFVb5PwwVr7KbMuCL8hVz8yx9aI9LAQu-gLKPpHpuKC7BnNwnPGlNq5rX6Io2IV49CD_EvLs8926p0YJ1mXR1IlsFlgrJ12LWOe4N8hdpk33tr6O4R9n8Vhw',
    };
  }

  return {
    alg: 'RSA-OAEP-256',
    e: 'AQAB',
    ext: true,
    key_ops: ['encrypt'],
    kty: 'RSA',
    n: 'zHkiEPyEiMKtkvuElcRdVwzU0EqfRPjP_fAUphE7EGOSkMeZGaDuXgmprZ1HauD6CU6u7SHoyz_w0a8afs6ZQGHyjLCg1i-BVclIr31_v2P36KCtR4Eqi9OjATBu_6xHd59aMaMygcS1IdXCva1kCIlNWfEP3vEmk6NVXy7ZR96pUyXNQ2AEHq01Qj3z4UHuBy1PraVheBN72-qfnPSx1XiTISWxDNvwQ5Eznv7xyH6pQC0jwJ4rVsiCrkv5uDxTJD_YCGdysxKX10i6gvM9MaqVPTEdopTHGLwu84TwKSmlaN8r1CEUCMo3zk-wYdGbY5BN7ywwsvsvtsVdX7hHaQ',
  };
};

export const AGENT_TYPES = {
  EMPLOYEES: 'Employees',
  GIG_AGENTS: 'Gig Agents',
};

export const NOT_APPLICABLE_VALUE = 'N/A';
