import {
  collection,
  deleteField,
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore';

import { ProjectsCollections } from '../consts';
import {
  AiPrompt,
  AiPromptChatConfig,
  AiPromptConfig,
  AiPromptDefaultKeys,
  AiSettings,
  AiTemplateType,
} from '../models/Prompts';
import { generalConverter } from '../utils';
import { projectDoc } from '.';

const CONFIG = 'config';

const CHAT_CONFIG = 'chatConfig';

const DEFAULT_KEYS = 'default';

const promptsCol = (projectId: string) =>
  collection(projectDoc(projectId), ProjectsCollections.Prompts);

const promptConfigDoc = (projectId: string) =>
  doc(promptsCol(projectId), CONFIG).withConverter(
    generalConverter<AiPromptConfig>(),
  );

const promptChatConfigDoc = (projectId: string) =>
  doc(promptsCol(projectId), CHAT_CONFIG).withConverter(
    generalConverter<AiPromptChatConfig>(),
  );

export const promptDefaultKeysDoc = (projectId: string) =>
  doc(promptsCol(projectId), DEFAULT_KEYS).withConverter(
    generalConverter<AiPromptDefaultKeys & { id: string }>(),
  );

export const getPromptConfig = async (
  projectId: string,
): Promise<AiPromptConfig | undefined> => {
  const config = await getDoc(promptConfigDoc(projectId));
  return config.data();
};

export const getPromptChatConfig = async (projectId: string) => {
  const config = await getDoc(promptChatConfigDoc(projectId));
  return config.data();
};

export const updatePromptChatConfig = (
  projectId: string,
  data: Partial<AiPromptChatConfig>,
) => setDoc(promptChatConfigDoc(projectId), data, { merge: true });

/**
 * Saves the AI prompt template configuration for a specific project.
 * @param projectId - The ID of the project.
 * @param values - The AI prompt values to be saved.
 * @param templateType - The type of AI template.
 * @returns A Promise that resolves when the configuration is saved.
 */
export const saveAiPromptTemplateConfig = async (
  projectId: string,
  values: AiPrompt,
  templateType: AiTemplateType,
): Promise<void> => {
  const payload =
    (await getDoc(promptConfigDoc(projectId))).data() ?? ({} as AiPromptConfig);
  // We will simply replacing the specific AI config
  payload[templateType] = values;
  await setDoc(promptConfigDoc(projectId), payload);
};

export const deleteAiPromptTemplateConfig = async (
  projectId: string,
  values: AiPrompt,
  templateType: AiTemplateType,
): Promise<void> => {
  await setDoc(
    promptConfigDoc(projectId),
    {
      [templateType]: deleteField(),
    },
    { merge: true },
  );
};

export const saveBaseTemplateConfig = async (
  projectId: string,
  value: string,
): Promise<void> => {
  await setDoc(
    promptConfigDoc(projectId),
    {
      template: value,
    },
    { merge: true },
  );
};

export const saveAiSettingsConfig = async (
  projectId: string,
  config: AiSettings,
): Promise<void> => {
  await setDoc(
    promptConfigDoc(projectId),
    {
      assistantId: config.assistantId,
    },
    { merge: true },
  );
};

export const getAiPromptDefaultKeys = async (projectId: string) => {
  const snapshot = await getDoc(promptDefaultKeysDoc(projectId));
  const { id, ...data } = snapshot.data() ?? {};
  return data;
};
