import { Agent, Bank } from '@agentsonly/models';
import { App, Col, FormInstance, Modal, Row, Typography } from 'antd';
import { useAtom } from 'jotai';
import { useImmerAtom } from 'jotai-immer';
import React, { useRef, useState } from 'react';

import { agentByIdAtom, agentsTableAtom } from '../../atoms/agent';
import { functions } from '../../firebase';
import { updateAgent } from '../../firebase/agent';
import { Document } from '../../firebase/models/Document';
import { Button } from '../../shared/ui/Button';
import { StyledForm } from './AgentInformation/components/GeneralInformation/styledComponents';
import { BankFields } from './BankFields';
import { LegalDocumentRow } from './LegalDocumentRow';
import { PersonalIdentificationField } from './PersonalIdentificationField';

const AGENT_FIELD_VALUE_EXIST_FUNC = 'agentFieldValueExists';
const { Paragraph, Text } = Typography;

type FormValues = Bank & { ssn?: string };

type Props = {
  agent: Agent;
  legalDocuments: Document[];
  disabled: boolean;
};

export const BankAndLegalDocumentSection: React.FC<Props> = ({
  agent,
  legalDocuments,
  disabled,
}) => {
  const { message } = App.useApp();
  const [confirmModal, setConfirmModal] = useState(false);
  const formRef = useRef<FormInstance>(null);
  const [agents, setAgents] = useAtom(agentByIdAtom);
  const [, setAgentsTable] = useImmerAtom(agentsTableAtom);
  const [loading, setLoading] = useState(false);

  const onUpdateAgent = async (data: FormValues) => {
    const { ssn, ...values } = data;

    try {
      const updatedValue: Bank = { ...agent.legal?.bank, ...values };
      await updateAgent(agent.id, {
        legal: { ssn: ssn ?? null, bank: updatedValue },
      });

      const updatedAgent = {
        [agent.id]: {
          ...agent,
          legal: { ssn: ssn ?? null, bank: updatedValue },
        },
      };
      setAgents({ ...agents, ...updatedAgent });
      setAgentsTable((draftAgentsTable) => {
        const agentIndex = draftAgentsTable.findIndex((a) => a.id === agent.id);
        if (agentIndex !== -1) {
          const tableAgent = draftAgentsTable[agentIndex];
          tableAgent.legal = { ssn: ssn ?? null, bank: updatedValue };
        }
      });
      message.success('Agent has been updated');
    } catch (err) {
      if (err instanceof Error) {
        message.error(`Couldn't update the Agent: ${err.message}`);
      }
    }
  };

  const onConfirmOkClick = async () => {
    setConfirmModal(false);
    const values = formRef.current?.getFieldsValue();
    if (values) {
      await onUpdateAgent(values);
    }
  };

  const onFinish = async (data: FormValues) => {
    setLoading(true);
    const { ssn } = data;
    const ssnDuplicateCheck = functions.httpsCallable(
      AGENT_FIELD_VALUE_EXIST_FUNC,
    );
    try {
      const hasSSN = !!ssn;
      const isDuplicateSSN =
        hasSSN && agent.legal?.ssn !== ssn
          ? await ssnDuplicateCheck({ fieldPath: 'legal.ssn', value: ssn })
          : false;

      const isOpenConfirmModal = isDuplicateSSN ? isDuplicateSSN.data : false;
      if (isOpenConfirmModal) {
        setConfirmModal(true);
      } else {
        await onUpdateAgent(data);
      }
    } catch (err) {
      if (err instanceof Error) {
        message.error(`Couldn't update the Agent: ${err.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <StyledForm
        layout="vertical"
        disabled={disabled}
        onFinish={onFinish}
        innerRef={formRef}
      >
        <Row>
          <Col span={24}>
            <Row justify="end">
              <Button
                type="primary"
                ghost
                htmlType="submit"
                loading={loading}
                disabled={disabled}
              >
                Update
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Paragraph strong>Bank Information</Paragraph>
            <Row>
              <Col span={24}>
                <Row justify="space-between">
                  <BankFields agent={agent} disabled={disabled} />
                </Row>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <PersonalIdentificationField agent={agent} disabled={disabled} />
            <Row>
              <Col span={24}>
                <Paragraph strong>Legal Agreement</Paragraph>
                {legalDocuments.length === 0 ? (
                  <Text>No agreement signed</Text>
                ) : (
                  legalDocuments.map((document) => (
                    <LegalDocumentRow key={document.id} document={document} />
                  ))
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledForm>
      <Modal
        open={confirmModal}
        onCancel={() => setConfirmModal(false)}
        onOk={onConfirmOkClick}
        okText="Continue"
        title="SSS is already in use by another Agent."
      />
    </>
  );
};
