import React from 'react';

import {
  QuestionKey,
  WorkExperienceDetail,
  WorkingExperienceType,
  WorkingExperienceTypeLabels,
} from '../../../firebase/agent/model';
import { BaseColors } from '../../../shared/ui/colors';
import { NOT_APPLICABLE_VALUE } from '../../../utils/consts';
import { ExperienceContainer } from '../ExperienceContainer';
import { StyledParagraph } from './styledComponents';

type Props = {
  workExperience?: WorkExperienceDetail;
};

export const ExperienceSection: React.FC<Props> = ({ workExperience }) => {
  const entries = Object.entries(workExperience ?? {});
  const notExperience = entries.every(([, value]) => value === undefined);
  const experience = notExperience ? (
    <StyledParagraph strong $color={BaseColors.inactive}>
      {NOT_APPLICABLE_VALUE}
    </StyledParagraph>
  ) : (
    entries
      .map(([key, value]) => {
        if (key === WorkingExperienceType.OTHERS && value) {
          return value.questions?.[QuestionKey.OTHER_EXPERIENCE];
        }
        return WorkingExperienceTypeLabels[key as WorkingExperienceType];
      })
      .join('; ')
  );

  return (
    <ExperienceContainer
      title="Past Work Experience"
      subTitle="Experience"
      content={experience}
    />
  );
};
