import { ISOCountry } from '@agentsonly/models';

import { NOT_APPLICABLE_VALUE } from '../../../../../utils/consts';
import { CA_PROVINCE } from './caProvince';
import { MX_STATES } from './mxStates';
import { PH_PROVINCE } from './phProvince';
import { US_STATES } from './usStates';

export const getTerritory = (countryCode?: ISOCountry, state?: string) => {
  if (!countryCode || !state) {
    return NOT_APPLICABLE_VALUE;
  }

  // If the state does not match the list, it will return the original value
  switch (countryCode) {
    case 'US':
      return US_STATES[state] ?? state;
    case 'MX':
      return MX_STATES[state] ?? state;
    case 'CA':
      return CA_PROVINCE[state] ?? state;
    case 'PH':
      return PH_PROVINCE[state] ?? state;
    default:
      return NOT_APPLICABLE_VALUE;
  }
};
