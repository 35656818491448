import { Agent } from '@agentsonly/models';

import {
  PlatformPayment,
  Referral,
} from '../../../../firebase/models/interfaces';
import {
  formatCSVData,
  InvoiceType,
  InvoiceTypeConfiguration,
} from './invoices';
import { WisePaymentEntry, wiseTitles } from './wiseTemplate';

type AgentPayment = Omit<Agent & PlatformPayment, 'createdAt'> & {
  referral: Referral;
};

export const createFileBody = (
  agents: Array<Agent>,
  invoices: Array<AgentPayment>,
  invoiceType: InvoiceTypeConfiguration,
): string => {
  let body: string;

  switch (invoiceType.type) {
    case InvoiceType.WISE: {
      body = formatCSVData(wiseTitles);
      break;
    }
    // case InvoiceType.SPEI: {
    //   body = formatCSVData(speiTitles);
    //   break;
    // }
    default:
      body = '';
      break;
  }

  // create a map of agent -> invoice array
  // const agentInvoiceMap: Map<string, Array<AgentInvoice>> = {};
  const agentInvoiceMap = invoices.reduce<Record<string, AgentPayment[]>>(
    (ap, invoice) => {
      if (!ap[invoice.agentId]) {
        // eslint-disable-next-line no-param-reassign
        ap[invoice.agentId] = [];
      }
      ap[invoice.agentId].push(invoice);
      return ap;
    },
    {},
  );

  agents.forEach((agent) => {
    // for each agent, create one entry per transaction ref
    // This handles the edge case where there were two invoices were generated for the same day

    if (!agentInvoiceMap[agent.id]) {
      // there are no unpaid invoices for this agent
      return;
    }

    const payments = agentInvoiceMap[agent.id];

    payments.forEach((payment) => {
      const paymentRef = payment.invoiceId ?? payment.reference;
      const paymentData = payment.data;

      if (!agent.legal || !agent.address) {
        const invalidAgentDetails = `Invalid address or legal/bank for agent ${agent.firstName} ${agent.lastName} (id: ${agent.id})\n`;
        console.log(invalidAgentDetails);

        // this will break the CSV file, but that's ok, we need to know when an agent has bad details
        body += invalidAgentDetails;
      } else {
        switch (invoiceType.type) {
          case InvoiceType.WISE: {
            const wiseEntry = new WisePaymentEntry(
              `${agent.firstName} ${agent.lastName}`,
              agent.email,
              paymentRef,
              payment.amount.toFixed(2),
              agent.address.countryCode,
              agent.address.city ?? '',
              agent.address.street ?? '',
              agent.address.state ?? '',
              agent.address.zip ?? '',
              agent.legal.bank,
              `${payment.referral.refereeFirstName} ${payment.referral.refereeLastName}`,
              paymentData ? `L${paymentData.referralLevel}` : '',
              paymentData ? `Gate ${paymentData.referralGate}` : '',
            );

            body += wiseEntry.toCSV();

            break;
          }
          //   case InvoiceType.SPEI: {
          //     if (ISOCountry.MX === agent.address.countryCode) {
          //       const temporaryRFC = 'AOC';
          //       const referenceNumber = format(
          //         new Date(),
          //         REFERENCE_NUMBER_FORMAT,
          //       );
          //       const clabe = agent.legal.bank.clabe ?? '';

          //       const spei = new SPEIEntry(
          //         clabe.substring(7, 16),
          //         temporaryRFC,
          //         clabe,
          //         clabe.substring(0, 2),
          //         `${agent.firstName} ${agent.lastName}`,
          //         sum.toFixed(2),
          //         referenceNumber,
          //         paymentRef,
          //       );

          //       body += `${spei.toCSV()}\n`;
          //     }
          //     break;
          //   }
          default: {
            body += ``;
            break;
          }
        }
      }
    });
  });

  return body;
};
