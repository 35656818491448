import { Client } from '@agentsonly/models';
import { atom } from 'jotai';

import { getClientsByProjectId } from '../firebase/client';
import { asyncAtom } from '../utils/atoms';
import { AsyncValue } from '../utils/types';

const projectClientAsyncValueAtom = atom<AsyncValue<Record<string, Client>>>({
  loading: true,
});

export const projectClientsAtom = asyncAtom<Record<string, Client>, string>(
  projectClientAsyncValueAtom,
  async (projectId, prev) => {
    const snapshot = await getClientsByProjectId(projectId);
    const clients = snapshot.docs.map((doc) => doc.data());
    return { ...(prev.data ?? {}), [projectId]: clients[0] };
  },
  true,
);
