import { Project, SECURITY_PARTNER } from '@agentsonly/models';
import { yupResolver } from '@hookform/resolvers/yup';
import { App } from 'antd';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { projectClientsAtom } from '../../../atoms/client';
import { projectByIdAtom, updateProjectAtom } from '../../../atoms/project';
import { useCloudTask } from '../../hooks/useCloudTask';
import { projectDetailsValidator } from './schema';

type FieldValues = Pick<Project, 'billingName' | 'billingAddress'>;

export const useProjectDetail = (project: Project) => {
  const { notification } = App.useApp();
  const [currentProject] = useAtom(projectByIdAtom);
  const [, updateProject] = useAtom(updateProjectAtom);
  const [{ data: projectClientMapping }, fetchProjectClientId] =
    useAtom(projectClientsAtom);
  const [isUpdatingTPProtect, setUpdatingTPProtect] = useState(false);
  const projectClient = projectClientMapping?.[project.id];

  const billingName = currentProject[project.id]?.billingName || '';
  const billingAddress = currentProject[project.id]?.billingAddress || '';

  const { updateCloudTask, isLoading, isEnabledCloudTasks } = useCloudTask(
    project.id,
  );

  const onToggleCloudTask = async (checked: boolean) => {
    try {
      await updateCloudTask(checked);
      notification.success({
        message: 'Cloud Task Configuration updated successfully',
      });
    } catch (err) {
      notification.error({
        message: 'Something went wrong, Please try again later',
      });
    }
  };

  const onToggleTPProtect = async (checked: boolean) => {
    try {
      setUpdatingTPProtect(true);

      const updatedEnabledSecurityPartners =
        project.enabledSecurityPartners ?? [];

      if (checked) {
        updatedEnabledSecurityPartners.push(SECURITY_PARTNER.TP_PROTECT);
      } else {
        const deleteTPProtectIndex = updatedEnabledSecurityPartners.findIndex(
          (item) => item === SECURITY_PARTNER.TP_PROTECT,
        );
        updatedEnabledSecurityPartners.splice(deleteTPProtectIndex, 1);
      }

      await updateProject({
        id: project.id,
        // Update Local Atoms as well
        // It can keep the same order when we'll have several security parters
        enabledSecurityPartners: updatedEnabledSecurityPartners.sort((a, b) =>
          a.localeCompare(b),
        ),
      });
      notification.success({
        message: 'TP Protect updated successfully',
      });
    } catch (err) {
      console.error(err);
      notification.error({
        message: 'Something went wrong, Please try again later',
      });
    } finally {
      setUpdatingTPProtect(false);
    }
  };

  const form = useForm<FieldValues>({
    mode: 'onChange',
    resolver: yupResolver(projectDetailsValidator),
    defaultValues: {
      billingName,
      billingAddress,
    },
  });

  const handleSubmit = async (values: FieldValues) => {
    try {
      await updateProject({ id: project.id, ...values });
      form.reset({ ...values });
      notification.success({ message: 'Project details updated successfully' });
    } catch (err) {
      notification.error({
        message: 'Something went wrong when updating project details',
      });
    }
  };

  const isEnabledTPProtect = project?.enabledSecurityPartners?.includes(
    SECURITY_PARTNER.TP_PROTECT,
  );

  useEffect(() => {
    form.reset({ billingName, billingAddress });
    if (!projectClient) {
      fetchProjectClientId(project.id);
    }
  }, [project.id, projectClientMapping]);

  return {
    projectClient,
    isEnabledTPProtect,
    handleSubmit,
    form,
    isUpdatingTPProtect,
    isLoading,
    onToggleTPProtect,
    onToggleCloudTask,
    isEnabledCloudTasks,
  };
};
