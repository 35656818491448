/* eslint-disable @typescript-eslint/no-shadow */
export enum RootCollections {
  Agents = 'agents',
  Projects = 'projects',
  ProjectContactRecords = 'projectContactRecords',
  PlatformPayments = 'platformPayments',
  PlatformInvoices = 'platformInvoices',
  PlatformRates = 'platformRates',
  Referrals = 'referrals',
  ReferralPlans = 'referralPlans',
  ExternalData = 'externalData',
  Configs = 'configs',
  AgentInvoiceStatuses = 'agentInvoiceStatuses',
  AdminRoles = 'adminRoles',
  AdminUsers = 'adminUsers',
  ClientUsers = 'clientUsers',
  ClientManualData = 'manualUploadData',
  Coaching = 'coaching',
  CSVUpload = 'csvUpload',
  Demo = 'demo',
  SKills = 'skills',
  Chats = 'chats',
  Clients = 'clients',
}

export enum ProjectsCollections {
  ExternalData = 'externalData',
  StarAlgorithms = 'starAlgorithms',
  ProjectAgents = 'projectAgents',
  ProjectStats = 'projectStats',
  Schedules = 'schedules',
  Prompts = 'prompts',
}

export enum ProjectStats {
  Project = 'project',
  Schedules = 'schedules',
}

export enum ExternalDataCollections {
  ContactIds = 'contactIds',
}

export enum AgentCollections {
  Documents = 'documents',
  Projects = 'projects',
  Skills = 'skills',
  AgentEnvironment = 'agentEnvironment',
  AgentStats = 'agentStats',
  WorkingExperience = 'workingExperience',
}

export enum AgentEnvironmentDocuments {
  PcCheck = 'pcCheck',
  Security = 'security',
  SpeedTest = 'speedTest',
}

export enum ProjectSchedulesCollections {
  Shifts = 'shifts',
  ShiftStats = 'shiftStats',
}

export enum ProjectContactRecordsCollections {
  ContactRecords = 'contactRecords',
  FailureConnectContactRecords = 'failureConnectContactRecords',
}

export enum PlatformInvoicesCollections {
  Invoices = 'invoices',
}

export enum AgentInvoiceStatusesCollections {
  Statuses = 'statuses',
}

export enum ConfigCollections {
  BackgroundCheck = 'backgroundCheck',
  Referrals = 'referrals',
  WebAdmin = 'webAdmin',
  CloudTasks = 'cloudTasks',
}

export enum ConfigReferralsCollections {
  Limits = 'limits',
}

export enum ClientManualDataCollections {
  Files = 'files',
}

export enum CsvUploadFilesCollections {
  ErrorRows = 'errorRows',
}

export enum CsvUploadCollections {
  Files = 'files',
}

export enum CoachingCollections {
  Behaviors = 'behaviors',
  Resources = 'resources',
  SubmissionGroups = 'submissionGroups',
  Achievements = 'achievements',
  Stats = 'stats',
}

export enum CoachingStatsCollections {
  TotalSubmissions = 'totalSubmissions',
  TotalAgentFeedback = 'totalAgentFeedback',
}

export enum DemoCollections {
  CronJob = 'cronJob',
  ProjectStats = 'projectStats',
}

export enum ProjectStatsCollections {
  Algorithms = 'algorithms',
  Schedules = 'schedules',
  Project = 'project',
}

export enum AgentProjectCollections {
  AgentProjectStats = 'agentProjectStats',
}

export enum AgentProjectStatsCollections {
  Algorithms = 'algorithms',
  Schedules = 'schedules',
}

export enum ChatsCollections {
  Contacts = 'contacts',
}
export enum ChatsContactsCollections {
  Messages = 'messages',
}
