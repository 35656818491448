import { BACKGROUND_FINAL_STATUS, REVIEW_STATUS } from '@agentsonly/models';
import {
  BackgroundFinalStatus,
  ReviewStatus,
} from '@agentsonly/models/types/BackgroundCheck';

export const WORKING_YEARS: Record<number, string> = {
  0: 'Less than 1 year',
  1: '1 - 3 years',
  2: '3 - 5 years',
  3: '5 - 10 years',
  4: 'More than 10 years',
};

export const GIG_COMMIT_HOURS: Record<number, string> = {
  0: 'Less than 10 hours',
  1: '10 - 20 hours',
  2: '20 - 30 hours',
  3: '30 - 40 hours',
  4: 'More than 40 hours',
};

export const WORKING_POSITIONS: Record<string, string> = {
  agent: 'Agent',
  mentor: 'Supervisor/ Team Leader/ Mentor',
  'operation-manage': 'Operation Manager (and up)',
  trainer: 'Trainer',
  'quality-assurance': 'Quality Assurance',
  wfm: 'WFM (Workforce Management)',
  expert: 'Subject Matter Expert (SME)',
};

export const NONE_VALUE = 'none';
export const AVERAGE_WPM = 35;
export const AVERAGE_ACC = 0.35;

export const BACKGROUND_CHECK_STATUS_FILTER_VALUES = [
  BACKGROUND_FINAL_STATUS.PASSED,
  BACKGROUND_FINAL_STATUS.FAILED,
  BACKGROUND_FINAL_STATUS.PENDING,
];

export const REVIEW_STATUS_FILTER_VALUES = [
  REVIEW_STATUS.REVIEWED,
  REVIEW_STATUS.PENDING,
];

export const BACKGROUND_CHECK_STATUS_FILTER_VALUE_LABEL: Record<
  BackgroundFinalStatus,
  string
> = {
  [BACKGROUND_FINAL_STATUS.PASSED]: 'Passed',
  [BACKGROUND_FINAL_STATUS.PENDING]: 'Pending',
  [BACKGROUND_FINAL_STATUS.FAILED]: 'Failed',
};

export const REVIEW_STATUS_FILTER_VALUE_LABEL: Record<ReviewStatus, string> = {
  [REVIEW_STATUS.REVIEWED]: 'Reviewed',
  [REVIEW_STATUS.PENDING]: 'Pending',
};
