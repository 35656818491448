import { add, format, parse, startOfWeek } from 'date-fns';

export const YEAR_MONTH_DAY_FORMAT = 'yyyyMMdd';
export const YEAR_WEEK_FORMAT = 'yyyyww';
export const YEAR_MONTH_FORMAT = 'yyyyMM';
export const US_LOCALE_FORMAT = 'PP';

export const YEAR_FORMAT = 'yyyy';
export const MONTH_FORMAT = 'MM';
export const WEEK_FORMAT = 'ww';
export const DAY_FORMAT = 'dd';

export const isValidDate = (date: string, dateFormat: string) => {
  try {
    const parsedDate = parse(date, dateFormat, new Date());
    return !Number.isNaN(parsedDate.getTime());
  } catch (err) {
    console.log(err);
  }
  return false;
};

export const getYearWeekFromDate = (date: Date): string =>
  format(startOfWeek(date), YEAR_WEEK_FORMAT, {
    useAdditionalWeekYearTokens: true,
  });

export const toLocalTime = (date: Date) =>
  add(date, {
    minutes: date.getTimezoneOffset(),
  });
