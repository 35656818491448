import { ApiCredentials } from '../../../../firebase/models/Prompts';
import { getCryptoPublicKey } from '../../../../utils/consts';
import { cryptoEncrypt } from '../../../../utils/crypto';

export const CREDENTIALS_PATH = 'settings.apiCredentials';

export const encryptAPIKey = async (
  originalApiCredentials?: ApiCredentials,
  apiKey?: string,
) => {
  if (!apiKey?.trim()) {
    return '';
  }

  const { maskedKey: originalMaskedKey, encryptedKey: originalEncryptedKey } =
    originalApiCredentials ?? {};

  // API key is not changed so returning the existing encryptedKey
  if (originalMaskedKey === apiKey) {
    return originalEncryptedKey ?? '';
  }

  const encryptedKey = await cryptoEncrypt(getCryptoPublicKey(), apiKey);
  return encryptedKey;
};

export const maskKey = (key: string) => `${key.slice(0, 3)}***${key.slice(-3)}`;
