import { Project } from '@agentsonly/models';
import { Button, Col, Row, Switch, Typography } from 'antd';
import { format } from 'date-fns';
import React from 'react';
import { FormProvider } from 'react-hook-form';

import { FormInput } from '../../../shared/ui/form/FormInput';
import { Spacer } from '../../../shared/ui/Spacer/styledComponents';
import { Heading } from '../styledComponents';
import { AGENT_TYPES, DEFAULT_VALUE } from './consts';
import { Container, StyledSpace } from './styledComponents';
import { useProjectDetail } from './useProjectDetail';

const { Text, Paragraph } = Typography;

interface Props {
  project: Project;
  disabled: boolean;
}

export const ProjectDetails: React.FC<Props> = ({ project, disabled }) => {
  const {
    projectClient,
    handleSubmit,
    isEnabledTPProtect,
    form,
    isUpdatingTPProtect,
    onToggleTPProtect,
    onToggleCloudTask,
    isLoading,
    isEnabledCloudTasks,
  } = useProjectDetail(project);
  const agentType = project.employeeProject
    ? AGENT_TYPES.EMPLOYEES
    : AGENT_TYPES.GIG_AGENTS;

  // If we set form.formState.isDirty directly
  // then we caught an issue that the form is unable to save if just typed one character
  // when first load
  const isFormDirty = form.formState.isDirty;
  const buttonDisabled = !form.formState.isValid || !isFormDirty || disabled;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Row>
          <Col span={24}>
            <Heading level={5}>Details</Heading>
          </Col>
          <Container span={22}>
            <Row gutter={80}>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Billing Name</Text>
                    <FormInput
                      name="billingName"
                      type="string"
                      placeholder="Enter Name"
                      height={40}
                    />
                  </StyledSpace>
                </Row>
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Billing Address</Text>
                    <FormInput
                      name="billingAddress"
                      type="textarea"
                      placeholder={`Street number and name\nCity, State\nPostal code, Country`}
                      height={100}
                    />
                  </StyledSpace>
                </Row>
                <Row>
                  <Button
                    loading={form.formState.isSubmitting}
                    htmlType="submit"
                    type="primary"
                    ghost
                    disabled={buttonDisabled}
                  >
                    Save Billing Information
                  </Button>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Project Name</Text>
                    <Text strong>{project.name}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={12} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Description</Text>
                    <Paragraph
                      strong
                      ellipsis={{ tooltip: project.description, rows: 4 }}
                    >
                      {project.description}
                    </Paragraph>
                  </StyledSpace>
                </Row>
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Agent Type</Text>
                    <Text>{agentType}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={12} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Cloud Task Configuration</Text>
                    <Switch
                      disabled={disabled}
                      checked={isEnabledCloudTasks}
                      loading={isLoading}
                      checkedChildren="On"
                      unCheckedChildren="Off"
                      onClick={onToggleCloudTask}
                    />
                  </StyledSpace>
                </Row>
              </Col>
              <Col span={8}>
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Project ID</Text>
                    <Text>{project.id}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={12} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Client ID</Text>
                    <Text strong>{projectClient?.name ?? DEFAULT_VALUE}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={12} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Active</Text>
                    <Text>{project.active.toString()}</Text>
                  </StyledSpace>
                </Row>
                <Spacer size={10} axis="vertical" />
                <Row>
                  <StyledSpace direction="vertical" size={8}>
                    <Text type="secondary">Created at</Text>
                    <Text>
                      {project.createdAt
                        ? format(project.createdAt, 'PPpp')
                        : ''}
                    </Text>
                  </StyledSpace>
                </Row>
              </Col>
            </Row>
            <Row gutter={80}>
              <Col span={8} /> {/* Empty Col For Spacing */}
              <Col span={8}>
                <Spacer axis="vertical" size={16} />
                <StyledSpace direction="vertical" size={8}>
                  <Text type="secondary">TP Protect</Text>
                  <Switch
                    loading={isUpdatingTPProtect}
                    checked={isEnabledTPProtect}
                    checkedChildren="On"
                    unCheckedChildren="Off"
                    onClick={onToggleTPProtect}
                    disabled={disabled}
                  />
                </StyledSpace>
              </Col>
              <Col span={8} /> {/* Empty Col For Spacing */}
            </Row>
          </Container>
        </Row>
      </form>
    </FormProvider>
  );
};
