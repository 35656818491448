export const PH_PROVINCE: Record<string, string> = {
  ABR: 'Abra',
  AGN: 'Agusan Del Norte',
  AGS: 'Agusan Del Sur',
  AKL: 'Aklan',
  ALB: 'Albay',
  ANT: 'Antique',
  APA: 'Apayao',
  AUR: 'Aurora',
  BAN: 'Bataan',
  BAS: 'Basilan',
  BTN: 'Batanes',
  BTG: 'Batangas',
  BEN: 'Benguet',
  BIL: 'Biliran',
  BOH: 'Bohol',
  BUK: 'Bukidnon',
  BUL: 'Bulacan',
  CAG: 'Cagayan',
  CAN: 'Camarines Norte',
  CAS: 'Camarines Sur',
  CAM: 'Camiguin',
  CAP: 'Capiz',
  CAT: 'Catanduanes',
  CAV: 'Cavite',
  CEB: 'Cebu',
  COM: 'Davao de Oro',
  DAV: 'Davao Del Norte',
  DAS: 'Davao Del Sur',
  DAO: 'Davao Oriental',
  DIN: 'Dinagat Islands',
  DVO: 'Davao Occidental',
  EAS: 'Eastern Samar',
  GUI: 'Guimaras',
  IFU: 'Ifugao',
  ILN: 'Ilocos Norte',
  ILI: 'Iloilo',
  ILS: 'Ilocos Sur',
  ISA: 'Isabela',
  KAL: 'Kalinga',
  LAN: 'Lanao Del Norte',
  LAS: 'Lanao Del Sur',
  LAG: 'Laguna',
  LEY: 'Leyte',
  LUN: 'La Union',
  MAD: 'Marinduque',
  MAG: 'Maguindanao',
  MAS: 'Masbate',
  MDC: 'Occidental Mindoro',
  MDR: 'Oriental Mindoro',
  MOU: 'Mountain Province',
  MSC: 'Misamis Occidental',
  MSR: 'Misamis Oriental',
  NEC: 'Negros Occidental',
  NER: 'Negros Oriental',
  NUE: 'Nueva Ecija',
  NUV: 'Nueva Vizcaya',
  NCR: 'Metro Manila',
  NCO: 'Cotabato',
  NSA: 'Northern Samar',
  PAM: 'Pampanga',
  PAN: 'Pangasinan',
  PLW: 'Palawan',
  QUE: 'Quezon',
  QUI: 'Quirino',
  RIZ: 'Rizal',
  ROM: 'Romblon',
  SAR: 'Sarangani',
  SCO: 'South Cotabato',
  SIG: 'Siquijor',
  SLE: 'Southern Leyte',
  SOR: 'Sorsogon',
  SLU: 'Sulu',
  SUK: 'Sultan Kudarat',
  SUN: 'Surigao Del Norte',
  SUR: 'Surigao Del Sur',
  TAR: 'Tarlac',
  TAW: 'Tawi-Tawi',
  WSA: 'Samar',
  ZAN: 'Zamboanga Del Norte',
  ZAS: 'Zamboanga Del Sur',
  ZMB: 'Zambales',
  ZSI: 'Zamboanga Sibugay',
};
