export const CHAT_GPT_MODEL = ['gpt-4o', 'gpt-4o-mini'] as const;

export const AI_PROVIDERS = {
  OPEN_AI: 'openai',
  OPEN_AI_ASSISTANT: 'openai-assistant',
  ANTHROPIC: 'anthropic',
  NONE: 'none',
} as const;

export type ChatGptModel = (typeof CHAT_GPT_MODEL)[number];

export type AiProvider = (typeof AI_PROVIDERS)[keyof typeof AI_PROVIDERS];

export type ApiCredentials = {
  encryptedKey?: string;
  maskedKey?: string;
};

export type BaseAiSettings = {
  provider: AiProvider;
  apiCredentials?: ApiCredentials;
};

export const Claude3Model = {
  Opus: 'claude-3-opus-20240229',
  Sonnet: 'claude-3-sonnet-20240229',
  Haiku: 'claude-3-haiku-20240307',
} as const;

export type Claude3ModelType = (typeof Claude3Model)[keyof typeof Claude3Model];

export type AnthropicAiSettings = BaseAiSettings & {
  model: Claude3ModelType;
  systemMessage: string;
};

export type OpenAiSettings = BaseAiSettings & {
  temperature: number;
  preferredModel: ChatGptModel;
};

export type OpenAiAssistantSettings = BaseAiSettings & {
  assistantId: string;
};

const AI_TEMPLATE_KEYS = [
  'insiderScoop',
  'dimensionTips',
  'chat',
  'coachingFeedback',
  'customDimensions',
  'agentMatching',
  'speechToText',
  'diarization',
] as const;

const AI_CHAT_KEYS = ['agentChat', 'clientContact'] as const;

export type AiTemplateType = (typeof AI_TEMPLATE_KEYS)[number];

export type AIChatType = (typeof AI_CHAT_KEYS)[number];

export type GenericAiPrompt<T> = {
  query?: string; // provides context specific to type of prompt
  settings: T;
};

export type AiTemplateConfig<T> = {
  insiderScoop: GenericAiPrompt<T>;
  dimensionTips: GenericAiPrompt<T>;
  chat: GenericAiPrompt<T>;
  coachingFeedback?: GenericAiPrompt<T>;
  customDimensions?: GenericAiPrompt<T>;
  speechToText: GenericAiPrompt<T>;
  agentMatching: GenericAiPrompt<T>;
  diarization: GenericAiPrompt<T>;
};

export type AiSettingType =
  | AnthropicAiSettings
  | OpenAiSettings
  | OpenAiAssistantSettings
  | BaseAiSettings;

export type AiPrompt = GenericAiPrompt<AiSettingType>;

export type BaseAiTemplateConfig = {
  template: string; // used with all prompts, provides context about the project and call
};

export type AiSettings = {
  assistantId?: string;
};

export type GenericAiPromptConfig<T> = BaseAiTemplateConfig &
  AiSettings &
  AiTemplateConfig<T>;

export type AiPromptConfig = GenericAiPromptConfig<AiSettingType>;

export type AiPromptChatConfig = {
  agents?: Record<string, AiPrompt>;
  clients?: Record<string, AiPrompt>;
};

export type AiPromptDefaultKeys = Partial<Record<AiProvider, ApiCredentials>>;
