import { Client } from '@agentsonly/models';
import { collection, getDocs, query, where } from 'firebase/firestore';

import { RootCollections } from '../consts';
import { db } from '../firebaseEntity';
import { generalConverter } from '../utils';

const getClientColRefV9 = () => collection(db, RootCollections.Clients);

export const getClientsByProjectId = (projectId: string) =>
  getDocs(
    query(
      getClientColRefV9().withConverter(generalConverter<Client>()),
      where('projects', 'array-contains', projectId),
    ),
  );
